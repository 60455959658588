import {
  createRouterMiddleware,
  createRouterReducer,
  ReduxRouterState,
} from '@lagunovsky/redux-react-router';
import {
  Action,
  AnyAction,
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
} from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import cartReducer from '../reducers/cartSlice';
import dialogReducer from '../reducers/dialogSlice';
import errorReducer from '../reducers/errorSlice';
import menuReducer from '../reducers/menuSlice';
import messagesReducer from '../reducers/messagingSlice';
import orderReducer from '../reducers/orderSlice';
import restaurantReducer from '../reducers/restaurantSlice';
import userReducer from '../reducers/userSlice';
import aiReducer from '../redux/features/ai/ai.slice';
import cachedMenuReducer from '../redux/features/cachedMenu/cachedMenu.slice';
import configReducer from '../redux/features/config/config.slice';
import healthStatusCheckReducer from '../redux/features/healthStatusCheck/healthStatusCheck.slice';
import loadingReducer from '../redux/features/loading/loading.slice';
import taskRouterReducer from '../redux/features/taskRouter/taskRouter.slice';
import sessionBoundaryReducer from '../redux/features/sessionBoundary/sessionBoundary.slice';
import autoComboReducer from '../redux/features/autoCombo/autoCombo.slice';
import { messagingTransformMiddleware, socketMiddleware } from './middleware';
import appContainer from '../utils/container';
import menuUnificationReducer from '../redux/features/menuUnification/menuUnification.slice';

const history = createBrowserHistory();
const appReducer = combineReducers({
  router: createRouterReducer(history) as Reducer<ReduxRouterState, AnyAction>,
  loading: loadingReducer,
  messages: messagesReducer,
  menu: menuReducer,
  cachedMenu: cachedMenuReducer,
  cart: cartReducer,
  ai: aiReducer,
  user: userReducer,
  restaurant: restaurantReducer,
  error: errorReducer,
  order: orderReducer,
  dialog: dialogReducer,
  config: configReducer,
  healthStatusCheck: healthStatusCheckReducer,
  taskRouter: taskRouterReducer,
  sessionBoundary: sessionBoundaryReducer,
  autoCombo: autoComboReducer,
  menuUnification: menuUnificationReducer,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === 'user/logout') {
    // reset all state except user state when logout action is dispatched
    const { user, ...rest } = state;
    const newState: any = {};
    Object.keys(state).forEach((key) => {
      if (key in rest) {
        newState[key] = undefined;
      }
    });

    state = {
      ...newState,
      user,
    };
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      createRouterMiddleware(history),
      messagingTransformMiddleware,
      socketMiddleware,
    ]),
});

appContainer.set('store', store);

export type AppDispatch = typeof store.dispatch;
export type RootStore = { getState: () => RootState; dispatch: AppDispatch };
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

/**
 * We are augmenting the redux's type definitions here instead of creating a *.d.ts file
 * because we need to access `RootState` and `AppDispatch` types.
 *
 * Notice `ThunkApiConfig` type is used as default type for thunkAPI (the third argument
 * of AsyncThunkPayloadCreator). It will help in destructuring thunkAPI with proper type
 * support for `getStore()` and `dispatch()` functions.
 */

declare module '@reduxjs/toolkit' {
  type ThunkApiConfig = {
    state: RootState;
    dispatch: AppDispatch;
  };

  export function createAsyncThunk<Returned, ThunkArg = void>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
      Returned,
      ThunkArg,
      ThunkApiConfig
    >,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>;
}
