import { GraphQLClient } from 'graphql-request/dist';
import { MenuStages } from '../constants/enums';
import {
  getSdk as createSdk,
  SdkFunctionWrapper,
} from '../generated-interfaces/graphql';
import { ConfigState } from '../redux/features/config/config.slice';
import { addQueryParamsToUrl } from './common';
import {
  RESTAURANT_DIAGNOSTIC_PASSWORD,
  RESTAURANT_DIAGNOSTIC_USERNAME,
} from './constants';
import { getAuthToken, saveAuthToken } from './local-storage';
import logger from './logger';
import { PersistentMenuProperty } from './menu';
import { nullOrUndefined } from './types';
import { sendNetworkCallLogs } from '../reducers/messagingSlice';
import { RootStore } from '../app/store';
import { PerfTimer } from './timer';
import appContainer from './container';
import { AUDIO_WS_PATH } from '../constants';
import { getAuthRefactorFeatureFlag } from './session-storage';
import { post } from './api';
import { IOrderItem } from '../types/brandMenu';

let client: GraphQLClient | null;
let authToken: string | nullOrUndefined;

export const AUTHORIZATION_HEADER = 'Authorization';

export const setAuthToken = (token: string | nullOrUndefined) => {
  if (!getAuthRefactorFeatureFlag()) {
    authToken = token;
    client = null; // Reset client so we use the new auth token
  }
  saveAuthToken(token);
};

export const fetchAuthToken = () => {
  if (getAuthRefactorFeatureFlag()) {
    return getAuthToken();
  } else {
    return authToken;
  }
};

interface ApiRequestResult<T> {
  data: T & { responseStatusCode: number };
  headers: Headers;
  status: number;
}

const apiCallWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
): Promise<T> => {
  const startTime = new Date().getTime();
  const result: ApiRequestResult<T> = (await action()) as any;
  const requestDuration = new Date().getTime() - startTime;
  result.data['responseStatusCode'] = result.status;
  logger.info({
    message: `API request duration(ms): ${requestDuration}`,
  });
  return result.data;
};

export const ISOStringToEpoch = (dateString: string) => {
  return new Date(dateString).getTime();
};

export const getWebsocketUrl = (
  {
    NODE_ENV,
    FEATURE_FLAG_MENU_VERSION,
    WEBSOCKET_STAGE_URL,
    WEBSOCKET_STAGE_BASIC_AUTH_PARAM,
    WEBSOCKET_URL,
    WEBSOCKET_BASIC_AUTH_PARAM,
  }: ConfigState,
  restaurantCode: string,
  selectedStage: string,
  isAudioWS = false
) => {
  // TODO: I think this should pull the root from the config instead of the whole env
  const isProdDraftStage =
    NODE_ENV.toUpperCase() === 'PRODUCTION' &&
    FEATURE_FLAG_MENU_VERSION &&
    selectedStage.toUpperCase() === MenuStages.PLAYGROUND;
  const urlPrefix = isProdDraftStage ? WEBSOCKET_STAGE_URL : WEBSOCKET_URL;
  const authParam = isProdDraftStage
    ? WEBSOCKET_STAGE_BASIC_AUTH_PARAM
    : WEBSOCKET_BASIC_AUTH_PARAM;
  return `${urlPrefix}${
    isAudioWS ? AUDIO_WS_PATH : ''
  }/${restaurantCode}/HITL_100?authorization=${authParam}`;
};

export const getPersistentMenuPropByRestaurant = async (
  prpPersistentApi: string,
  restaurantCode: string,
  params: { [key: string]: string }
) => {
  const store = appContainer.get<RootStore>('store')!;
  const url = addQueryParamsToUrl(
    `${prpPersistentApi}/persistent-menu-property/${restaurantCode}`,
    params
  );
  let persistentMenuProp: PersistentMenuProperty[] = [];

  const fetchedAuthToken = fetchAuthToken();

  if (fetchedAuthToken) {
    const requestTimer = new PerfTimer();
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: fetchedAuthToken,
        },
      })
    ).json();
    persistentMenuProp = data;

    const duration = requestTimer.stop();
    if (data?.error_code) {
      logger.error({
        message: 'HTTP error: getPersistentMenuPropByRestaurant',
        moreInfo: {
          url,
          errorCode: data?.error_code,
          errorMessage: data?.error_message,
        },
      });

      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request failed',
          via: 'fetch',
        })
      );

      if (getAuthRefactorFeatureFlag()) {
        throw data.error_code;
      }
    } else {
      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request succeeded',
          via: 'fetch',
        })
      );
    }
  } else {
    const message =
      'AUTH error: No auth token found while fetching persistent menu property';
    logger.error({ message });
    if (getAuthRefactorFeatureFlag()) {
      throw message;
    }
  }
  return persistentMenuProp;
};

export const getGraphQLClient = (prpApi: string) => {
  if (getAuthRefactorFeatureFlag()) {
    const graphqlClient = new GraphQLClient(`${prpApi}/graphql`);
    const fetchedAuthToken = getAuthToken();

    if (fetchedAuthToken) {
      graphqlClient.setHeader(AUTHORIZATION_HEADER, fetchedAuthToken);
    }

    return createSdk(graphqlClient, apiCallWrapper);
  } else {
    if (!client) {
      client = new GraphQLClient(`${prpApi}/graphql`);
      if (authToken) {
        client.setHeader(AUTHORIZATION_HEADER, authToken);
      }
    }
    return createSdk(client, apiCallWrapper);
  }
};

export const sleep = async (timeToWait: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeToWait));
};

// This function is not used, can be deleted
export const getLatestMenuVersionAPI = async (
  menuApi: string,
  stage: MenuStages,
  params: { [key: string]: string }
) => {
  const store = appContainer.get<RootStore>('store')!;
  const url = addQueryParamsToUrl(
    `${menuApi}/${stage.toLocaleLowerCase()}/menu/commit/active`,
    params
  );
  let MenuRespons: any = {};

  const fetchedAuthToken = fetchAuthToken();

  if (fetchedAuthToken) {
    const requestTimer = new PerfTimer();
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: fetchedAuthToken,
        },
      })
    ).json();
    MenuRespons = data;
    const duration = requestTimer.stop();
    if (data?.error_code) {
      logger.error({
        message: 'HTTP error: getLatestMenuVersionAPI',
        moreInfo: {
          url,
          errorCode: data?.error_code,
          errorMessage: data?.error_message,
        },
      });

      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request failed',
          via: 'fetch',
        })
      );

      throw data.error_code;
    } else {
      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request succeeded',
          via: 'fetch',
        })
      );
    }
  } else {
    const message =
      'AUTH error: No auth token found while fetching getLatestMenuVersionAPI';
    logger.error({ message });
    if (getAuthRefactorFeatureFlag()) {
      throw message;
    }
  }

  return MenuRespons;
};

export const getMenuURLFromMenuAPI = async (
  menuApi: string,
  stage: MenuStages,
  params: { [key: string]: string }
) => {
  const store = appContainer.get<RootStore>('store')!;
  const url = addQueryParamsToUrl(
    `${menuApi}/${stage.toLocaleLowerCase()}/menu`,
    params
  );
  let MenuRespons: any = {};

  const fetchedAuthToken = fetchAuthToken();

  if (fetchedAuthToken) {
    const requestTimer = new PerfTimer();
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: fetchedAuthToken,
        },
      })
    ).json();
    MenuRespons = data;
    const duration = requestTimer.stop();
    if (data?.error_code) {
      logger.error({
        message: 'HTTP error: getMenuURLFromMenuAPI',
        moreInfo: {
          url,
          errorCode: data?.error_code,
          errorMessage: data?.error_message,
        },
      });

      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request failed',
          via: 'fetch',
        })
      );

      throw data.error_code;
    } else {
      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request succeeded',
          via: 'fetch',
        })
      );
    }
  } else {
    const message =
      'AUTH error: No auth token found while fetching getMenuURLFromMenuAPI';
    logger.error({ message });
    if (getAuthRefactorFeatureFlag()) {
      throw message;
    }
  }

  return MenuRespons;
};

export const getMenuFromMenuAPI = async (
  menuURL: string,
  params: { [key: string]: string }
) => {
  const store = appContainer.get<RootStore>('store')!;
  const url = addQueryParamsToUrl(menuURL, params);
  let MenuRespons: any = {};

  const fetchedAuthToken = fetchAuthToken();

  if (fetchedAuthToken) {
    const requestTimer = new PerfTimer();
    const response = await fetch(url, {
      method: 'GET',
    });
    const duration = requestTimer.stop();
    if (!response?.ok) {
      logger.error({
        message: 'HTTP error: getMenuFromMenuAPI',
        moreInfo: {
          url,
          responseStatus: response?.status,
          responseStatusText: response?.statusText,
        },
      });

      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request failed',
          via: 'fetch',
        })
      );

      throw response.status;
    } else {
      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request succeeded',
          via: 'fetch',
        })
      );
    }
    MenuRespons = await response.json();
  } else {
    const message =
      'AUTH error: No auth token found while fetching getMenuFromMenuAPI';
    logger.error({ message });
    if (getAuthRefactorFeatureFlag()) {
      throw message;
    }
  }

  return MenuRespons;
};

export const getMenuVersionsFromMenuAPI = async (
  menuApi: string,
  params: {
    restaurant_code: string;
  }
) => {
  const store = appContainer.get<RootStore>('store')!;
  const url = addQueryParamsToUrl(
    `${menuApi}/${MenuStages.PLAYGROUND.toLowerCase()}/menu-commit-stage`,
    params
  );
  let MenuRespons: any = {};

  const fetchedAuthToken = fetchAuthToken();

  if (fetchedAuthToken) {
    const requestTimer = new PerfTimer();
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: fetchedAuthToken,
        },
      })
    ).json();
    MenuRespons = data;
    const duration = requestTimer.stop();
    if (data?.error_code) {
      logger.error({
        message: 'HTTP error: getMenuVersionsFromMenuAPI',
        moreInfo: {
          url,
          errorCode: data?.error_code,
          errorMessage: data?.error_message,
        },
      });

      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request failed',
          via: 'fetch',
        })
      );

      throw data.error_code;
    } else {
      store.dispatch(
        sendNetworkCallLogs({
          url,
          duration,
          message: 'api request succeeded',
          via: 'fetch',
        })
      );
    }
  } else {
    const message =
      'AUTH error: No auth token found while fetching getMenuVersionsFromMenuAPI';
    logger.error({ message });
    if (getAuthRefactorFeatureFlag()) {
      throw message;
    }
  }

  return MenuRespons;
};

export const getRestaurantDiagnostic = async (
  restaurantDiagnosticApi: string,
  restaurantCode: string,
  successCallback: (data: any) => void
) => {
  const store = appContainer.get<RootStore>('store')!;
  const url = `${restaurantDiagnosticApi}/${restaurantCode}`;
  const authInfo = `${RESTAURANT_DIAGNOSTIC_USERNAME}:${RESTAURANT_DIAGNOSTIC_PASSWORD}`;
  const requestTimer = new PerfTimer();
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Basic ' + btoa(authInfo),
      },
    });

    store.dispatch(
      sendNetworkCallLogs({
        url,
        duration: requestTimer.stop(),
        message: 'api request succeeded',
        via: 'fetch',
      })
    );

    const json = await response.json();
    successCallback(json);
  } catch (error) {
    logger.error({
      restaurantCode,
      message: 'Get restaurant diagnostic failed',
      error,
    });

    store.dispatch(
      sendNetworkCallLogs({
        url,
        duration: requestTimer.stop(),
        message: 'api request failed',
        via: 'fetch',
      })
    );
  }
};

interface PagerDutyIncident {
  summary: string;
  details: Record<string, any>;
  links?: {
    href: string;
    text: string;
  }[];
  images?: {
    src: string;
    alt: string;
    href?: string;
  }[];
  integrationKey: string;
}

export async function createPagerDutyIncident({
  summary,
  details,
  links,
  images,
  integrationKey,
}: PagerDutyIncident) {
  const store = appContainer.get<RootStore>('store')!;
  const body = {
    links,
    images,
    routing_key: integrationKey,
    event_action: 'trigger',
    client: 'HITL Web Interface',
    client_url: window.location.origin,
    payload: {
      summary,
      severity: 'critical',
      source: window.location.href,
      component: 'HITL',
      custom_details: details,
    },
  };

  const requestTimer = new PerfTimer();
  const url = 'https://events.pagerduty.com/v2/enqueue';

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  store.dispatch(
    sendNetworkCallLogs({
      url,
      duration: requestTimer.stop(),
      message: 'api request ' + (response?.ok ? 'succeeded' : 'failed'),
      via: 'fetch',
    })
  );

  return response.json();
}

export const sendMenuUnificationOrder = async (
  MU_MENU_API: string,
  MU_MENU_API_TOKEN: string,
  restaurantCode: string,
  order: IOrderItem[],
  menuUnificationOrderFailureCallback?: Function,
  menuUnificationOrderSuccessCallback?: Function
) => {
  try {
    const response = await post({
      url: `${MU_MENU_API}/menu/order?restaurant_code=${restaurantCode}`,
      data: JSON.stringify(order),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${MU_MENU_API_TOKEN}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      menuUnificationOrderFailureCallback?.({ error: response.status });
    }
    console.log('Order sent to menu module', response);
  } catch (error) {
    console.error('Failed to send order to menu module', error);
    menuUnificationOrderFailureCallback?.({ error: error.message });
  }
};

export const getMenuURLFromMenuUnificationMenuAPI = async (
  menuURL: string,
  MU_MENU_API_TOKEN: string
) => {
  const store = appContainer.get<RootStore>('store')!;
  let MenuRespons: any = {};

  if (MU_MENU_API_TOKEN) {
    const requestTimer = new PerfTimer();
    const response = await fetch(menuURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${MU_MENU_API_TOKEN}`,
      },
    });
    const duration = requestTimer.stop();
    if (!response?.ok) {
      logger.error({
        message: 'HTTP error: getMenuFromMenuAPI',
        moreInfo: {
          menuURL,
          responseStatus: response?.status,
          responseStatusText: response?.statusText,
        },
      });

      store.dispatch(
        sendNetworkCallLogs({
          url: menuURL,
          duration,
          message: 'api request failed',
          via: 'fetch',
        })
      );

      throw response.status;
    } else {
      store.dispatch(
        sendNetworkCallLogs({
          url: menuURL,
          duration,
          message: 'api request succeeded',
          via: 'fetch',
        })
      );
    }
    MenuRespons = await response.json();
  } else {
    const message =
      'AUTH error: No menu module token found while fetching getMenuURLFromMenuUnificationMenuAPI';
    logger.error({ message });
    if (getAuthRefactorFeatureFlag()) {
      throw message;
    }
  }
  return MenuRespons;
};
